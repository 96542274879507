import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../hooks/context/user/UserContext';
import { IChild } from '../../interfaces/common/IChild';
import TokenUtils from '../../utils/TokenUtils';
import { Navigate } from 'react-router-dom';
import Loader from './Loader';

export default function PrivateRoute({ children }: IChild) {
  if (TokenUtils.instance.isTokenSet()) {
    return <UserInit>{children}</UserInit>;
  } else {
    return <Navigate replace to="/auth/login" />;
  }
}

function UserInit({ children }: IChild) {
  const user = useContext(UserContext).user;
  // const [isFirstTime, setIsFirstTime] = useState(true);

  useEffect(() => {
    user.toogleCall();
  }, []);

  if (user.data) {
    return <>{children}</>;
  } else {
    return <Loading />;
  }
}

function Loading() {
  return (
    <div className="flex flex-row justify-center">
      <div className="mx-8 flex flex-row justify-center items-center min-h-screen">
        <Loader
          height="h-8"
          width="w-8"
          textColor={'text-blue-600'}
          fillColor={'fill-white'}
        />
      </div>
    </div>
  );
}
