import { Outlet, useNavigate } from 'react-router-dom';
import TokenUtils from '../../utils/TokenUtils';
import ExtensionUtils from '../../utils/ExtensionUtils';

export default function DashboardPage() {
  return (
    <div>
      <div className="flex flex-row justify-center">
        <SideBar />
        <div className="px-8 py-8 flex-grow">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

function SideBar() {
  const navigate = useNavigate();

  const onSignOut = () => {
    TokenUtils.instance.clear();
    ExtensionUtils.instance.signOut();
    navigate('/auth/login');
  };

  return (
    <div
      className="pl-2 pr-3 py-4 flex flex-col min-h-screen bg-slate-900"
      style={{ width: '288px' }}
    >
      <SideBarTitle />
      <div className="mb-4" />
      <SideBarItem title="Home" onClick={() => {}} />
      {/* <div className="mb-1" />
      <SideBarItem title="Support" onClick={() => {}} /> */}
      <div className="flex-grow" />
      <SideBarItem title="Sign Out" onClick={onSignOut} />
      <div className="mb-4" />
    </div>
  );
}

function SideBarTitle() {
  return (
    <div className="w-full px-4 py-2 text-xl text-white font-medium text-start">
      Grammar Guru
    </div>
  );
}

function SideBarItem(props: { title: string; onClick: () => void }) {
  return (
    <button
      className="w-full px-4 py-2 text-base text-gray-400 font-medium text-start rounded-lg hover:text-white focus:text-white hover:bg-slate-800 focus:bg-slate-800 focus:outline-none"
      onClick={props.onClick}
    >
      <div>{props.title}</div>
    </button>
  );
}
