class AppConfig {
  private static _instance: AppConfig;

  public static get instance() {
    if (!AppConfig._instance) {
      AppConfig._instance = new AppConfig();
    }

    return AppConfig._instance;
  }

  private constructor() {}

  public get extensionId() {
    return 'oicgeoijlheakjdemeokabibobfoknpp';
  }

  public get edgeExtensionId() {
    return 'dnpimnggmocnmgpjecicjklcpigkplhb';
  }

  public get chromeExtensionId() {
    return 'ofghbfemmlaolkfgmocbadcmbemhikho';
  }
}

export default AppConfig;
