import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './fonts.css';
import 'react-toastify/dist/ReactToastify.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import LoginPage from './routes/auth/Login';
import SignUpPage from './routes/auth/SignUp';
import ForgotPasswordPage from './routes/auth/ForgotPassword';
import { ToastContainer } from 'react-toastify';
import PrivateRoute from './components/common/PrivateRoute';
import PublicRoute from './components/common/PublicRoute';
import { UserProvider } from './hooks/context/user/UserProvider';
import DashboardPage from './routes/dashboard/Dashboard';
import App from './routes/App';
import HomeContent from './routes/dashboard/HomeContent';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <PrivateRoute>
            <DashboardPage />
          </PrivateRoute>
        ),
        children: [
          {
            path: '/',
            element: <HomeContent />,
          },
        ],
      },
      {
        path: '/auth',
        children: [
          {
            path: '/auth/login',
            element: (
              <PublicRoute>
                <LoginPage />
              </PublicRoute>
            ),
          },
          {
            path: '/auth/signup',
            element: (
              <PublicRoute>
                <SignUpPage />
              </PublicRoute>
            ),
          },
          {
            path: '/auth/forgotpassword',
            element: (
              <PublicRoute>
                <ForgotPasswordPage />
              </PublicRoute>
            ),
          },
        ],
      },
    ],
    errorElement: <div>Error</div>,
  },
]);

root.render(
  // <React.StrictMode>
  // <App />
  // </React.StrictMode>
  <>
    <UserProvider>
      <RouterProvider router={router} />
    </UserProvider>
    <ToastContainer />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
