import { useEffect } from 'react';
import { ApiUtils } from '../utils/ApiUtils';
import { Outlet, useNavigate } from 'react-router-dom';

export default function App() {
  const navigate = useNavigate();

  useEffect(() => {
    ApiUtils.instance.init({
      onRefreshFailed: () => {
        navigate('/auth/login');
      },
    });
  }, []);

  return <Outlet />;
}
