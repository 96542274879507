import IUserToken from '../interfaces/user/IUserToken';

class TokenUtils {
  private static _instance: TokenUtils;

  public static get instance() {
    if (!TokenUtils._instance) {
      TokenUtils._instance = new TokenUtils();
    }

    return TokenUtils._instance;
  }

  private constructor() {}

  public clear() {
    localStorage.clear();
  }

  public set(props: IUserToken) {
    localStorage.setItem('token', JSON.stringify(props));
  }

  public get() {
    const token = localStorage.getItem('token');
    if (token) {
      const obj: IUserToken = JSON.parse(token);
      return obj;
    }

    return undefined;
  }

  public isTokenSet() {
    return this.get() !== undefined;
  }
}

export default TokenUtils;
