import { useContext } from 'react';
import { UserContext } from '../../hooks/context/user/UserContext';
import { IChild } from '../../interfaces/common/IChild';
import { useApi } from '../../hooks/UseApi';
import { ApiUtils } from '../../utils/ApiUtils';
import IPaymentResponseOrder from '../../interfaces/payment/IPaymentResponseOrder';
import IPaymentRequestOrder from '../../interfaces/payment/IPaymentRequestOrder';

export default function HomeContent() {
  return (
    <div className="w-full flex flex-col">
      <Title />
      <div className="mb-8" />
      <CreditView />
      <div className="mb-4" />
      <PaymentView />
    </div>
  );
}

function Title() {
  return <div className="text-2xl text-gray-900 font-semibold">Home</div>;
}

function Content({ children }: IChild) {
  return (
    <>
      <div className="p-6 rounded-lg border border-gray-300">{children}</div>
    </>
  );
}

function CreditView() {
  const user = useContext(UserContext).user;
  const credits = user.data?.credits ?? 0;

  return (
    <Content>
      <div className="flex flex-col">
        <div className="mb-2 text-base text-gray-900 font-medium">
          Credits Left
        </div>
        <div className="flex flex-row items-end">
          <div className="mb-2 mr-2 text-3xl text-gray-900 font-bold">
            {credits}
          </div>
          <div className="mb-3 text-base text-gray-900 font-medium">
            {'/~ AI Text Rewrites'}
          </div>
        </div>
      </div>
    </Content>
  );
}

function PaymentView() {
  const user = useContext(UserContext).user;

  const onOrderCreated = (props: IPaymentResponseOrder) => {
    ApiUtils.instance.openPaymentFlow({
      id: props.orderId,
      amount: props.orderDetails.amount,
      currency: props.orderDetails.currency,
      user: user.data!,
    });
  };

  const order = useApi<IPaymentRequestOrder, IPaymentResponseOrder>({
    api: (input) => ApiUtils.instance.createPaymentOrder(input!),
    onSuccess: (response, input, output) => {
      onOrderCreated(output);
    },
  });

  const onOrderClicked = (props: IPaymentRequestOrder) => {
    order.toogleCall(props);
  };

  return (
    <Content>
      <div className="flex flex-col">
        <div className="mb-2 text-base text-gray-900 font-medium">
          Buy More Credits
        </div>
        <div className="flex flex-col items-start">
          <PaymentItem
            title="Get 800 Credits For $10"
            onClick={() => onOrderClicked({ amount: 10 })}
          />
          <div className="mb-2" />
          <PaymentItem
            title="Get 4400 Credits For $50 (10% Discounts)"
            onClick={() => onOrderClicked({ amount: 50 })}
          />
          <div className="mb-2" />
          <PaymentItem
            title="Get 9600 Credits For $100 (20% Discounts)"
            onClick={() => onOrderClicked({ amount: 100 })}
          />
          {/* <div className="mb-2 mr-2 text-3xl text-gray-900 font-bold">
            {credits}
          </div>
          <div className="mb-3 text-base text-gray-900 font-medium">
            {'/~ AI Text Rewrites'}
          </div> */}
        </div>
      </div>
    </Content>
  );
}

function PaymentItem(props: { title: string; onClick: () => void }) {
  return (
    <button
      className="px-6 py-4 text-base text-white font-normal text-center rounded-lg bg-blue-600 hover:bg-blue-700 focus:bg-blue-700 focus:outline-none flex justify-center"
      onClick={props.onClick}
    >
      <div>{props.title}</div>
    </button>
  );
}
