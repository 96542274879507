import {
  AuthBottomInfo,
  AuthContinueButton,
  AuthInputField,
  AuthTitle,
  AuthTopInfo,
} from '../../components/common/AuthComponent';

export default function ForgotPasswordPage() {
  return (
    <div>
      <div className="flex flex-row justify-center">
        <div className="flex-grow"></div>
        <div
          className="mx-8 flex flex-row justify-center items-center min-h-screen"
          style={{ width: '384px' }}
        >
          <ForgotPasswordBody />
        </div>
        <div className="flex-grow"></div>
      </div>
    </div>
  );
}

function ForgotPasswordBody() {
  return (
    <div className="my-8 w-full flex flex-col items-center">
      <AuthTitle title="Reset your password" />
      <div className="my-2" />
      <AuthTopInfo text="Enter your email address and we will send you instructions to reset your password" />
      <div className="my-4" />
      <AuthInputField
        id="email"
        label="Email"
        placeholder="mail@example.com"
        type="email"
        autoFocus
      />
      <div className="my-3" />
      <AuthContinueButton />
      <div className="my-2" />
      <AuthBottomInfo lable="Back to login" url="/auth/login" />
    </div>
  );
}
