import {
  AuthBottomInfo,
  AuthContinueButton,
  AuthInputField,
  AuthTitle,
} from '../../components/common/AuthComponent';
import { useApi } from '../../hooks/UseApi';
import { ApiUtils } from '../../utils/ApiUtils';
import { useState } from 'react';
import ToastUtils from '../../utils/ToastUtils';
import IUserRequestSignUp from '../../interfaces/user/IUserRequestSignUp';

export default function SignUpPage() {
  return (
    <div>
      <div className="flex flex-row justify-center">
        <div className="flex-grow"></div>
        <div
          className="mx-8 flex flex-row justify-center items-center min-h-screen"
          style={{ width: '384px' }}
        >
          <SignUpBody />
        </div>
        <div className="flex-grow"></div>
      </div>
    </div>
  );
}

function SignUpBody() {
  const signUp = useApi<IUserRequestSignUp, {}>({
    api: (input) => ApiUtils.instance.signUp(input!),
    onSuccess: async (response, input, output) => {
      // await TokenUtils.instance.set(output);
      // navigate('/');
    },
    onError: (error) => {
      ToastUtils.instance.showError({ text: error.message });
    },
  });

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const onSubmit = (text: string) => {
    setConfirmPassword(text);
    onSignUp();
  };

  function onSignUp() {
    let tName = name.trim();
    let tEmail = email.trim();
    let tPassword = password.trim();
    let tConfirmPassword = confirmPassword.trim();

    if (tName.length === 0) {
      ToastUtils.instance.showError({ text: 'Please enter your name' });
      return;
    }

    if (tEmail.length === 0) {
      ToastUtils.instance.showError({ text: 'Please enter your email' });
      return;
    }

    if (tPassword.length === 0) {
      ToastUtils.instance.showError({ text: 'Please enter your password' });
      return;
    }

    if (tPassword !== tConfirmPassword) {
      ToastUtils.instance.showError({ text: "Confirm password doesn't match" });
      return;
    }

    signUp.toogleCall({
      name: name,
      email: email,
      password: password,
    });
  }

  return (
    <div className="my-8 w-full flex flex-col items-center">
      <AuthTitle title="Create your account" />
      <div className="my-4" />
      <AuthInputField
        id="name"
        label="Name"
        placeholder="Name"
        type="text"
        onChange={setName}
        autoFocus
      />
      <div className="my-1" />
      <AuthInputField
        id="email"
        label="Email"
        placeholder="mail@example.com"
        type="email"
        onChange={setEmail}
      />
      <div className="my-1" />
      <AuthInputField
        id="password"
        label="Password"
        placeholder="Password"
        type="password"
        onChange={setPassword}
      />
      <div className="my-1" />
      <AuthInputField
        id="confirm_password"
        label="Confirm Password"
        placeholder="Confirm Password"
        type="password"
        onChange={setConfirmPassword}
        onSubmit={onSubmit}
      />
      {!signUp.isLoading && signUp.statusCode === 200 && (
        <VerifyEmailInfo email={email} />
      )}
      <div className="my-3" />
      <AuthContinueButton onClick={onSignUp} isLoading={signUp.isLoading} />
      <div className="my-2" />
      <AuthBottomInfo
        text="Already have an account?"
        lable="Log in"
        url="/auth/login"
      />
    </div>
  );
}

function VerifyEmailInfo(props: { email: string }) {
  return (
    <div className="w-full mt-4 p-4 text-blue-800 border border-blue-300 rounded-lg bg-blue-50">
      <h3 className="text-lg font-medium">Verify your email address</h3>
      <div className="mt-2 text-sm">
        {`A verification link has been sent to ${props.email}. Please click on the link to verify your email address.`}
      </div>
    </div>
  );
}
