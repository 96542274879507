import { useNavigate } from 'react-router-dom';
import {
  AuthBottomInfo,
  AuthContinueButton,
  AuthInputField,
  AuthLink,
  AuthResendEmailButton,
  AuthTitle,
} from '../../components/common/AuthComponent';
import { useApi } from '../../hooks/UseApi';
import IUserRequestLogin from '../../interfaces/user/IUserRequestLogin';
import IUserToken from '../../interfaces/user/IUserToken';
import { ApiUtils } from '../../utils/ApiUtils';
import ToastUtils from '../../utils/ToastUtils';
import TokenUtils from '../../utils/TokenUtils';
import { useState } from 'react';
import IUserRequestResendEmail from '../../interfaces/user/IUserRequestResendEmail';
import ExtensionUtils from '../../utils/ExtensionUtils';

export default function LoginPage() {
  return (
    <div>
      <div className="flex flex-row justify-center">
        <div className="flex-grow"></div>
        <div
          className="mx-8 flex flex-row justify-center items-center min-h-screen"
          style={{ width: '384px' }}
        >
          <LoginBody />
        </div>
        <div className="flex-grow"></div>
      </div>
    </div>
  );
}

function LoginBody() {
  const navigate = useNavigate();

  const login = useApi<IUserRequestLogin, IUserToken>({
    api: (input) => ApiUtils.instance.login(input!),
    onSuccess: async (response, input, output) => {
      await TokenUtils.instance.set(output);
      ExtensionUtils.instance.signIn();
      navigate('/');
    },
    onError: (error) => {
      if (error.error_code !== 'EMAIL_IS_NOT_VERIFIED') {
        ToastUtils.instance.showError({ text: error.message });
      }
    },
  });

  const resendEmail = useApi<IUserRequestResendEmail, {}>({
    api: (input) => ApiUtils.instance.resendEmail(input!),
    onSuccess: async (response, input, output) => {
      ToastUtils.instance.showInfo({ text: 'Link sent! Check Your Inbox.' });
    },
    onError: (error) => {
      ToastUtils.instance.showError({ text: error.message });
    },
  });

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [emailInApi, setEmailInApi] = useState(email);

  const onSubmit = (text: string) => {
    setPassword(text);
    onLogin();
  };

  function onLogin() {
    let tEmail = email.trim();
    let tPassword = password.trim();

    if (tEmail.length === 0) {
      ToastUtils.instance.showError({ text: 'Please enter your email' });
      return;
    }

    if (tPassword.length === 0) {
      ToastUtils.instance.showError({ text: 'Please enter your password' });
      return;
    }

    setEmailInApi(() => email);
    login.toogleCall({
      email: email,
      password: password,
    });
  }

  function onResendEmail() {
    resendEmail.toogleCall({
      email: emailInApi,
    });
  }

  return (
    <div className="my-8 w-full flex flex-col items-center">
      <AuthTitle title="Welcome back" />
      <div className="my-4" />
      <AuthInputField
        id="email"
        label="Email"
        placeholder="mail@example.com"
        type="email"
        onChange={setEmail}
        autoFocus
      />
      <div className="my-1" />
      <AuthInputField
        id="password"
        label="Password"
        placeholder="Password"
        type="password"
        onChange={setPassword}
        onSubmit={onSubmit}
      />
      <div className="my-2" />
      <div className="w-full">
        <AuthLink lable="Forgot Password?" url="/auth/forgotpassword" />
      </div>
      {!login.isLoading &&
        login.error &&
        login.error.error_code === 'EMAIL_IS_NOT_VERIFIED' && (
          <VerifyEmailInfo
            email={emailInApi}
            isLoading={resendEmail.isLoading}
            onClick={onResendEmail}
          />
        )}
      <div className="my-3" />
      <AuthContinueButton onClick={onLogin} isLoading={login.isLoading} />
      <div className="my-2" />
      <AuthBottomInfo
        text="Don't have an account?"
        lable="Sign up"
        url="/auth/signup"
      />
    </div>
  );
}

function VerifyEmailInfo(props: {
  email: string;
  isLoading: boolean;
  onClick: () => void;
}) {
  return (
    <div className="w-full mt-4 p-4 text-blue-800 border border-blue-300 rounded-lg bg-blue-50">
      <h3 className="text-lg font-medium">Email not verified</h3>
      <div className="my-2 text-sm">
        {`A verification link has been sent to ${props.email}. Please click on the link to verify your email address.`}
      </div>
      <AuthResendEmailButton
        isLoading={props.isLoading}
        onClick={props.onClick}
      />
    </div>
  );
}
