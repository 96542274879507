import { useEffect, useState } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  IApiErrorResponse,
  IApiSuccessResponse,
} from '../interfaces/api/IApiResponse';

export interface IUseApi<S, T> {
  isLoading: boolean;
  statusCode?: number;
  data?: T;
  error?: IApiErrorResponse;
  toogleCall: (input?: S) => void;
}

interface IUseApiProps<S, T> {
  autoCall?: boolean;
  initialInput?: S;
  api: (input?: S) => Promise<AxiosResponse<Record<string, any>>>;
  onSuccess?: (
    response: AxiosResponse<Record<string, any>>,
    input: S | undefined,
    output: T
  ) => void;
  onError?: (error: IApiErrorResponse) => void;
}

export function useApi<S, T>(props: IUseApiProps<S, T>) {
  const autoCall = props.autoCall;
  const onSuccess = props.onSuccess;
  const onError = props.onError;
  const [statusCode, setStatusCode] = useState<number | undefined>();
  const [data, setData] = useState<T | undefined>();
  const [error, setError] = useState<IApiErrorResponse | undefined>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const onGenericError = () => {
    let err: IApiErrorResponse = {
      error_code: 'UNKNOWN',
      message: 'Something went wrong. Please try again',
    };

    setStatusCode(undefined);
    setData(undefined);
    setError(err);
    onError?.(err);
  };

  const onSpecificError = (
    response: AxiosResponse | undefined,
    json: Record<string, any>
  ) => {
    if (json && json['error_code'] && json['message']) {
      let error = json as IApiErrorResponse;

      setStatusCode(response?.status);
      setData(undefined);
      setError(error);
      onError?.(error);
    } else {
      onGenericError();
    }
  };

  const fetchData = async (input?: S): Promise<void> => {
    if (isLoading) return;

    try {
      // setData(undefined);
      setStatusCode(undefined);
      setError(undefined);
      setLoading(true);

      const response = await props.api(input);

      if (response.status === 200) {
        const json = response.data as IApiSuccessResponse<T>;
        setStatusCode(response.status);
        setData(json.data);
        onSuccess?.(response, input, json.data);
      } else {
        let json = response.data;
        onSpecificError(response, json);
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const error = e as AxiosError;
        let json = error.response?.data;
        if (json) {
          onSpecificError(error.response, json);
        } else {
          onGenericError();
        }
      } else {
        onGenericError();
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (autoCall) {
      fetchData(props.initialInput);
    }
  }, []);

  const toogleCall = (input?: S) => {
    fetchData(input);
  };

  const obj: IUseApi<S, T> = {
    isLoading: isLoading,
    statusCode: statusCode,
    data: data,
    error: error,
    toogleCall: toogleCall,
  };

  return obj;
}
