import axios, { AxiosResponse } from 'axios';
import TokenUtils from './TokenUtils';
import IUserRequestLogin from '../interfaces/user/IUserRequestLogin';
import IUserRequestSignUp from '../interfaces/user/IUserRequestSignUp';
import IUserRequestResendEmail from '../interfaces/user/IUserRequestResendEmail';
import {
  IApiErrorResponse,
  IApiSuccessResponse,
} from '../interfaces/api/IApiResponse';
import IUserAccessToken from '../interfaces/user/IUserAccessToken';
import IPaymentRequestOrder from '../interfaces/payment/IPaymentRequestOrder';
import IPaymentRequestPayment from '../interfaces/payment/IPaymentRequestPayment';

export class ApiUtils {
  private static _instance: ApiUtils;

  public static get instance() {
    if (!ApiUtils._instance) {
      ApiUtils._instance = new ApiUtils();
    }

    return ApiUtils._instance;
  }

  private constructor() {}

  private static readonly getMethod: string = 'GET';
  private static readonly postMethod: string = 'POST';

  private static get commonHeaders(): Record<string, any> {
    return {
      'Content-Type': 'application/json',
    };
  }

  private static async commonHeadersWithToken(token?: string) {
    const headers = this.commonHeaders;
    if (token) {
      headers['Authorization'] = 'Bearer ' + token;
    }

    return headers;
  }

  private static async commonHeadersWithAccessToken() {
    const token = await TokenUtils.instance.get();
    return ApiUtils.commonHeadersWithToken(token?.accessToken);
  }

  private static async commonHeadersWithRefreshToken() {
    const token = await TokenUtils.instance.get();
    return ApiUtils.commonHeadersWithToken(token?.refreshToken);
  }

  private static readonly baseUrl =
    'https://g-extension-api.calmhill-bad22c34.eastus.azurecontainerapps.io';

  private static readonly loginUrl = '/v1/api/user/login';
  private static readonly signUpUrl = '/v1/api/user/signup';
  private static readonly resendEmailUrl =
    '/v1/api/user/resend-verification-email';
  private static readonly refreshTokenUrl = '/v1/api/user/refresh-access-token';
  private static readonly userProfileUrl = '/v1/api/user/my-profile';
  private static readonly createPaymentOrderUrl = '/v1/api/user/order';
  private static readonly razorpayCallbackUrl =
    ApiUtils.baseUrl + '/v1/api/webhook/payment-success-webhook';

  private static readonly razorpayTestKey = 'rzp_test_StSQqqqyScKI5N';
  private static readonly razorpayProdKey = 'rzp_live_ikZCFJFrMyDmrY';

  private readonly api = axios.create({
    baseURL: ApiUtils.baseUrl,
  });

  private readonly apiRT = axios.create({
    baseURL: ApiUtils.baseUrl,
  });

  public init(props: { onRefreshFailed: () => void }) {
    this.api.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        const oldJson = error.response.data;
        let oldError: IApiErrorResponse | undefined = undefined;
        if (oldJson && oldJson['error_code'] && oldJson['message']) {
          oldError = oldJson as IApiErrorResponse;
        }

        if (
          oldError?.error_code === 'INVALID_TOKEN' &&
          !originalRequest._retry
        ) {
          // if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const newResponse = await this.refreshToken();
            if (newResponse.status === 200) {
              const newJson =
                newResponse.data as IApiSuccessResponse<IUserAccessToken>;

              const token = await TokenUtils.instance.get();
              if (token) {
                token.accessToken = newJson.data.accessToken;
                await TokenUtils.instance.set(token);
              }
            } else {
              await TokenUtils.instance.clear();
              props.onRefreshFailed();
              return Promise.reject(error);
            }

            originalRequest.headers =
              await ApiUtils.commonHeadersWithAccessToken();
            return this.api(originalRequest);
          } catch (refreshError) {
            await TokenUtils.instance.clear();
            props.onRefreshFailed();
            return Promise.reject(refreshError);
          }
        }

        return Promise.reject(error);
      }
    );
  }

  public async login(props: IUserRequestLogin): Promise<AxiosResponse> {
    return await this.api.post(ApiUtils.loginUrl, props, {
      headers: ApiUtils.commonHeaders,
    });
  }

  public async signUp(props: IUserRequestSignUp): Promise<AxiosResponse> {
    return await this.api.post(ApiUtils.signUpUrl, props, {
      headers: ApiUtils.commonHeaders,
    });
  }

  public async resendEmail(
    props: IUserRequestResendEmail
  ): Promise<AxiosResponse> {
    return await this.api.post(ApiUtils.resendEmailUrl, props, {
      headers: ApiUtils.commonHeaders,
    });
  }

  public async refreshToken(): Promise<AxiosResponse> {
    return await this.apiRT.post(
      ApiUtils.refreshTokenUrl,
      {},
      {
        headers: await ApiUtils.commonHeadersWithRefreshToken(),
      }
    );
  }

  public async userProfile(): Promise<AxiosResponse> {
    return await this.api.get(ApiUtils.userProfileUrl, {
      headers: await ApiUtils.commonHeadersWithAccessToken(),
    });
  }

  public async createPaymentOrder(
    props: IPaymentRequestOrder
  ): Promise<AxiosResponse> {
    return await this.api.post(ApiUtils.createPaymentOrderUrl, props, {
      headers: await ApiUtils.commonHeadersWithAccessToken(),
    });
  }

  public async openPaymentFlow(props: IPaymentRequestPayment) {
    var options = {
      key: ApiUtils.razorpayProdKey, // Enter the Key ID generated from the Dashboard
      amount: props.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: props.currency,
      // name: 'Acme Corp', //your business name
      // description: 'Test Transaction',
      // image: 'https://example.com/your_logo',
      order_id: props.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      callback_url: ApiUtils.razorpayCallbackUrl,
      prefill: {
        // We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
        name: props.user?.name ?? '', //your customer's name
        email: props.user?.email ?? '',
        // contact: '9000090000', //Provide the customer's phone number for better conversion rates
      },
      // notes: {
      //   address: 'Razorpay Corporate Office',
      // },
      // theme: {
      //   color: '#3399cc',
      // },
    };
    var rzp = new (window as any).Razorpay(options);
    rzp.open();
  }
}
