import { IChild } from '../../../interfaces/common/IChild';
import IUserProfile from '../../../interfaces/user/IUserProfile';
import { ApiUtils } from '../../../utils/ApiUtils';
import ExtensionUtils from '../../../utils/ExtensionUtils';
import { useApi } from '../../UseApi';
import { UserContext } from './UserContext';

export function UserProvider({ children }: IChild) {
  const user = useApi<{}, IUserProfile>({
    api: (input) => ApiUtils.instance.userProfile(),
    onSuccess: (response, input, output) => {
      ExtensionUtils.instance.alreadySignedIn();
    },
  });

  return (
    <UserContext.Provider value={{ user: user }}>
      {children}
    </UserContext.Provider>
  );
}
