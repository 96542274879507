import { ToastOptions, toast } from 'react-toastify';

class TokenUtils {
  private static _instance: TokenUtils;

  public static get instance() {
    if (!TokenUtils._instance) {
      TokenUtils._instance = new TokenUtils();
    }

    return TokenUtils._instance;
  }

  private constructor() {}

  private get options(): ToastOptions {
    return {
      hideProgressBar: true,
      autoClose: 3000,
      className: 'text-gray-900',
    };
  }

  public showInfo(props: { text: string }): void {
    toast.info(props.text, this.options);
  }

  public showError(props: { text: string }): void {
    toast.error(props.text, this.options);
  }
}

export default TokenUtils;
