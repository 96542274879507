import AppConfig from '../configs/AppConfig';
import {
  IExtensionAlreadySignedIn,
  IExtensionSignIn,
  IExtensionSignOut,
} from '../interfaces/extension/IExtension';
import TokenUtils from './TokenUtils';

class ExtensionUtils {
  private static _instance: ExtensionUtils;

  public static get instance() {
    if (!ExtensionUtils._instance) {
      ExtensionUtils._instance = new ExtensionUtils();
    }

    return ExtensionUtils._instance;
  }

  private constructor() {}

  private async send(id: string, props: string) {
    try {
      await chrome.runtime.sendMessage(id, props);
    } catch (_) {}
  }

  private async sendMessage(props: string) {
    this.send(AppConfig.instance.extensionId, props);
    this.send(AppConfig.instance.edgeExtensionId, props);
    this.send(AppConfig.instance.chromeExtensionId, props);
  }

  public signIn() {
    const token = TokenUtils.instance.get();
    if (token) {
      const message: IExtensionSignIn = {
        type: 'SIGN_IN',
        data: token,
      };
      this.sendMessage(JSON.stringify(message));
    }
  }

  public alreadySignedIn() {
    const token = TokenUtils.instance.get();
    if (token) {
      const message: IExtensionAlreadySignedIn = {
        type: 'ALREADY_SIGNED_IN',
        data: token,
      };
      this.sendMessage(JSON.stringify(message));
    }
  }

  public signOut() {
    const message: IExtensionSignOut = {
      type: 'SIGN_OUT',
    };
    this.sendMessage(JSON.stringify(message));
  }
}

export default ExtensionUtils;
