import { IChild } from '../../interfaces/common/IChild';
import TokenUtils from '../../utils/TokenUtils';
import { Navigate } from 'react-router-dom';

export default function PublicRoute({ children }: IChild) {
  if (TokenUtils.instance.isTokenSet()) {
    return <Navigate replace to="/" />;
  } else {
    return <>{children}</>;
  }
}
