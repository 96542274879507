import React from 'react';
import IUserProfile from '../../../interfaces/user/IUserProfile';
import { IUseApi } from '../../UseApi';

interface IUseUser {
  user: IUseApi<{}, IUserProfile>;
}

export const UserContext = React.createContext<IUseUser>({
  user: null!,
});
